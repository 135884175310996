import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
} from "@mui/material"
import { ErrorContent } from "./ErrorContent"

interface Props {
  error: Error
  helpMsg?: string
  dialogProps?: DialogProps
  handleClose?: () => void
}

export const ErrorView: React.FC<Props> = ({
  dialogProps,
  handleClose,
  ...rest
}) => {
  if (!dialogProps) {
    return <ErrorContent {...rest} />
  }

  const { open, onClose } = dialogProps

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <ErrorContent {...rest} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
