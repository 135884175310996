interface RedirectProps {
  href: string
  host?: string
  port?: string
  hash?: string
  hostname?: string
  pathname?: string
  protocol?: string
  search?: string
}

export const redirect = (uri: RedirectProps) => {
  window.location.href = uri.href
}
