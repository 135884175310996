import { Box, Divider, Grid } from "@mui/material"
import { format } from "date-fns"
import * as React from "react"
import OneIDLogo from "../../assets/oneid-brand.svg"
import { PublicFooterBox } from "./styles"

export const PublicFooter: React.FC = () => {
  const year = format(new Date(), "y")

  return (
    <PublicFooterBox>
      <Divider
        sx={{ marginBottom: "1rem", width: "100%" }}
        className="divider"
      />

      <Grid
        className="info-wrapper"
        justifyContent="center"
        alignItems="center"
        container
      >
        <Box sx={{ width: "60px", display: "flex", alignItems: "center" }}>
          <img src={OneIDLogo} alt="OneID" />
        </Box>
        <p className="copyright">© {year} All Rights Reserved</p>
      </Grid>
    </PublicFooterBox>
  )
}
