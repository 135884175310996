import { Alert, Snackbar } from "@mui/material"
import React from "react"
import { SnackbarContext } from "./SnackbarContext"

export const SharedSnackbar: React.FC = () => {
  const { isOpen, message, severity, close } = React.useContext(SnackbarContext)

  return (
    <Snackbar
      open={isOpen}
      onClose={close}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Alert severity={severity} variant="filled" onClose={close}>
        {message.split(/\n/).map((t, i, ar) => (
          <React.Fragment key={`error-${i}`}>
            {t}
            {ar.length > 1 && <br />}
          </React.Fragment>
        ))}
      </Alert>
    </Snackbar>
  )
}
