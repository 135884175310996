export function urlParamsToObject(urlParams: URLSearchParams) {
  const entries = urlParams.entries()
  const result: { [key: string]: string | null } = {}

  for (const [key, value] of entries) {
    let newValue

    switch (value) {
      case "undefined":
        continue
      case "null":
        newValue = null
        break
      default:
        newValue = value
        break
    }

    result[key] = newValue
  }

  return result
}
