import { Typography } from "@mui/material"
import { Config } from "../../config"
import ErrorSvg from "./error.svg"
import { ErrorMessage } from "./ErrorMessage"
import { ErrorContentBox } from "./styles"

interface Props {
  error: Error
  helpMsg?: string
}

export const ErrorContent: React.FC<Props> = ({ error, helpMsg }) => {
  return (
    <ErrorContentBox>
      <img src={ErrorSvg} alt="" className="image" />

      <Typography>
        <Typography color="inherit" variant="h5" gutterBottom>
          Shoot! We've encountered an error.
        </Typography>

        {helpMsg && <Typography color="inherit">{helpMsg}</Typography>}

        <Typography color="inherit" component="div">
          <ErrorMessage error={error} showStack={Config.DEBUG} />
        </Typography>
      </Typography>
    </ErrorContentBox>
  )
}
