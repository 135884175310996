export const openEmailApp = (email: string, callback?: () => void) => {
  const domain = email.split("@")[1]

  if (domain.includes("gmail")) {
    callback && callback()
    window.open("https://mail.google.com", "_blank")
  }

  if (domain.includes("yahoo")) {
    callback && callback()
    window.open("https://mail.yahoo.com", "_blank")
  }

  if (domain.includes("outlook") || domain.includes("live")) {
    callback && callback()
    window.open("https://login.live.com", "_blank")
  }

  if (domain.includes("hotmail")) {
    callback && callback()
    window.open("https://login.microsoft.com", "_blank")
  }

  if (domain.includes("aol")) {
    callback && callback()
    window.open("https://login.aol.com", "_blank")
  }

  callback && callback()
  window.open(`https://${domain}`, "_blank")
}
