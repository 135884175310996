export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum ActivityType {
  LOGIN = 'login',
  LOOKUP = 'lookup',
  OTHERS = 'others',
  REPORT = 'report'
}

export type Activity = {
  __typename?: 'Activity';
  _id?: Maybe<Scalars['ID']>;
  client?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  flagged?: Maybe<Scalars['Boolean']>;
  os?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ActivityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivityInput = {
  activityID: Scalars['ID'];
  businessID: Scalars['ID'];
  staffID: Scalars['ID'];
};

export type AdminLoginInput = {
  adminCode?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type AdminUpdatePasswordInput = {
  adminCode?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
};

export enum ApiClient {
  BUSINESS = 'business',
  DEVELOPER = 'developer'
}

export type ApiKey = {
  __typename?: 'ApiKey';
  apiKey?: Maybe<Scalars['String']>;
  assigned?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<ScopeOutput>;
  status?: Maybe<Status>;
  totalCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyDashboardSummaryStatsOutPut = {
  __typename?: 'ApiKeyDashboardSummaryStatsOutPut';
  apiKeysFailedCountForUser?: Maybe<Scalars['Int']>;
  apiKeysSuccessCountForUser?: Maybe<Scalars['Int']>;
  apiKeysTotalCountForUser?: Maybe<Scalars['Int']>;
};

export type ApiKeyHistory = {
  __typename?: 'ApiKeyHistory';
  _id?: Maybe<Scalars['ID']>;
  apiKeyId?: Maybe<Scalars['ID']>;
  cost?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  scope?: Maybe<ScopeOutput>;
  serviceName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ApiKeyHistoryGraph = {
  __typename?: 'ApiKeyHistoryGraph';
  count?: Maybe<Scalars['Int']>;
  period?: Maybe<Scalars['String']>;
};

export type ApiKeyHistoryMix = {
  __typename?: 'ApiKeyHistoryMix';
  _id?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['String']>;
  apiKeyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  scope?: Maybe<ScopeOutput>;
  serviceName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type AppointmentPass = {
  __typename?: 'AppointmentPass';
  _id?: Maybe<Scalars['ID']>;
  appointment?: Maybe<VisitorAppointment>;
  business?: Maybe<BusinessAccount>;
  user?: Maybe<User>;
};

export type AttendanceInput = {
  attendanceID: Scalars['ID'];
  businessID: Scalars['ID'];
  staffID: Scalars['ID'];
};

export type AuthUserResponse = {
  __typename?: 'AuthUserResponse';
  mfaEnabled?: Maybe<Scalars['Boolean']>;
  mfaType?: Maybe<MfaType>;
  refreshToken?: Maybe<Scalars['String']>;
  sentTo?: Maybe<Scalars['String']>;
  showTourGuide?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export enum BillStatus {
  EXPIRED = 'expired',
  ONGOING = 'ongoing',
  OVERDUE = 'overdue',
  PAID = 'paid'
}

export type Bills = {
  __typename?: 'Bills';
  api_key?: Maybe<Scalars['String']>;
  api_keyId?: Maybe<Scalars['ID']>;
  assigned?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  status?: Maybe<BillStatus>;
  total?: Maybe<Scalars['Float']>;
};

export type BillsEstimate = {
  __typename?: 'BillsEstimate';
  billIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  total?: Maybe<Scalars['String']>;
};

export type BusinessAccount = {
  __typename?: 'BusinessAccount';
  _id?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  businessEmail?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fullAddress?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  kycVerified?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  oneId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  verifyAppointments?: Maybe<Scalars['Boolean']>;
  verifyVisitors?: Maybe<Scalars['Boolean']>;
};

export type BusinessAdmin = {
  __typename?: 'BusinessAdmin';
  _id?: Maybe<Scalars['ID']>;
  adminCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasUpdatedPassword?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  oneId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type BusinessCustomer = {
  __typename?: 'BusinessCustomer';
  _id?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  oneID?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BusinessVisitor = {
  __typename?: 'BusinessVisitor';
  _id?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  appointments?: Maybe<Array<Maybe<VisitorAppointment>>>;
  businessID?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  oneID?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BusinessVisitorsAppointment = {
  __typename?: 'BusinessVisitorsAppointment';
  _id?: Maybe<Scalars['ID']>;
  appointmentPass?: Maybe<AppointmentPass>;
  businessDetails?: Maybe<BusinessAccount>;
  businessID?: Maybe<Scalars['ID']>;
  completed?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  floorNumber?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  isElapsed?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tagNumber?: Maybe<Scalars['String']>;
  toSee?: Maybe<Scalars['String']>;
  vendorItems?: Maybe<Array<Maybe<Items>>>;
  visitationReason?: Maybe<Scalars['String']>;
  visitingUserId?: Maybe<Scalars['ID']>;
  visitor?: Maybe<BusinessVisitor>;
};

export enum ConsoleEnv {
  LIVE = 'live',
  SANDBOX = 'sandbox'
}

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type ChangePassword = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Chat = {
  __typename?: 'Chat';
  _id?: Maybe<Scalars['ID']>;
  authorEmail?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['ID']>;
  authorUsername?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatInput = {
  message: Scalars['String'];
};

export type CountryList = {
  __typename?: 'CountryList';
  geonameId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateActivityInput = {
  activity?: InputMaybe<Scalars['String']>;
  businessID: Scalars['ID'];
  staffID: Scalars['ID'];
  typeOf: Scalars['String'];
};

export type CreateAttendanceInput = {
  businessID: Scalars['ID'];
  checkIn?: InputMaybe<Scalars['DateTime']>;
  staffID: Scalars['ID'];
};

export type CreateStaffInput = {
  designation: Scalars['String'];
  emailOrOneID: Scalars['String'];
};

export type Criminal = {
  __typename?: 'Criminal';
  _id: Scalars['ID'];
  courtOrder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  jailTime?: Maybe<Scalars['String']>;
  jailTimeComplete?: Maybe<Scalars['Boolean']>;
  offence?: Maybe<Scalars['String']>;
  policeOrder?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
};

export type CustomerAttendance = {
  __typename?: 'CustomerAttendance';
  _id?: Maybe<Scalars['ID']>;
  businessID?: Maybe<Scalars['ID']>;
  checkIn?: Maybe<Scalars['DateTime']>;
  checkOut?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<BusinessCustomer>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeleteStaffInput = {
  businessID: Scalars['ID'];
  staffID: Scalars['ID'];
};

export type DeveloperConsoleProfile = {
  __typename?: 'DeveloperConsoleProfile';
  RcNo?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  about?: Maybe<Scalars['String']>;
  businessEmail?: Maybe<Scalars['String']>;
  businessLogo?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessWebsite?: Maybe<Scalars['String']>;
  environment?: Maybe<ConsoleEnv>;
  oneId?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
};

export type Education = {
  __typename?: 'Education';
  _id?: Maybe<Scalars['ID']>;
  certification?: Maybe<Scalars['String']>;
  course?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  grade?: Maybe<Scalars['String']>;
  institutionType?: Maybe<Scalars['String']>;
  schoolAddress?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  _id?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type Employment = {
  __typename?: 'Employment';
  _id?: Maybe<Scalars['ID']>;
  appointmentYear?: Maybe<Scalars['DateTime']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyWebsite?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  office?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  terminationYear?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  mimetype: Scalars['String'];
};

export type Filter = {
  dateFrom?: InputMaybe<Scalars['String']>;
  dateTo?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<ConsoleEnv>;
  scope?: InputMaybe<ForEnum>;
  status?: InputMaybe<Status>;
};

export enum ForEnum {
  BILLING = 'billing',
  BVNVERIFICATION = 'bvnVerification',
  CACVERIFICATION = 'cacVerification',
  DEVELOPER = 'developer',
  EDUCATION = 'education',
  EMERGENCY = 'emergency',
  FRONTDESK = 'frontDesk',
  KYC_BVN = 'kyc_bvn',
  KYC_NIN = 'kyc_nin',
  MEDICAL = 'medical',
  METERNUMBERADDRESS = 'meterNumberAddress',
  NATIONALITY = 'nationality',
  NEXTOFKIN = 'nextOfKin',
  NINVERIFICATION = 'ninVerification',
  PERSONAL = 'personal',
  PHONEVERIFICATION = 'phoneVerification',
  TRAVEL = 'travel',
  WORKEXPERIENCE = 'workExperience'
}

export type ForInput = {
  name?: InputMaybe<ForEnum>;
  read?: InputMaybe<Scalars['Boolean']>;
  write?: InputMaybe<Scalars['Boolean']>;
};

export type ForOutput = {
  __typename?: 'ForOutput';
  cost?: Maybe<Scalars['Float']>;
  name?: Maybe<ForEnum>;
  read?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
};

export type GenerateTransactionData = {
  amount: Scalars['String'];
  currency: Scalars['String'];
  type: TypeEnum;
  userid: Scalars['String'];
};

export type GraphFilter = {
  dateFrom?: InputMaybe<Scalars['String']>;
  dateTo?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<ConsoleEnv>;
  period?: InputMaybe<PeriodEnum>;
  status?: InputMaybe<HistoryStatus>;
};

export type History = {
  __typename?: 'History';
  accessed?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
};

export enum HistoryStatus {
  FAILED = 'failed',
  SUCCESS = 'success'
}

export type Invoice = {
  __typename?: 'Invoice';
  _id?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['String']>;
  apiKeyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assigned?: Maybe<Scalars['ID']>;
  billIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  billingPeriod?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentStatus>;
};

export enum KycType {
  BVN = 'bvn',
  NIN = 'nin'
}

export type LgaList = {
  __typename?: 'LGAList';
  geonameId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MfaResponse = {
  __typename?: 'MFAResponse';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export enum MfaType {
  EMAIL_ONLY = 'email_only',
  EMAIL_PHONE = 'email_phone',
  PHONE_ONLY = 'phone_only',
  TWOFA = 'twoFA'
}

export type Medical = {
  __typename?: 'Medical';
  _id?: Maybe<Scalars['ID']>;
  ailment?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  costOfService?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorName?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  hospitalAddress?: Maybe<Scalars['String']>;
  lastVisit?: Maybe<Scalars['DateTime']>;
  serverity?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
};

export type MeterDetails = {
  __typename?: 'MeterDetails';
  Address?: Maybe<Scalars['String']>;
  Customer_Name?: Maybe<Scalars['String']>;
  MeterNumber?: Maybe<Scalars['String']>;
  Provider?: Maybe<Scalars['String']>;
};

export type MeterNumberVerifications = {
  __typename?: 'MeterNumberVerifications';
  _id?: Maybe<Scalars['ID']>;
  business?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<MeterVerificationDetails>;
  status?: Maybe<MeterVerificationStatusEnum>;
};

export type MeterVerificationDetails = {
  __typename?: 'MeterVerificationDetails';
  Address?: Maybe<Scalars['String']>;
  Customer_Name?: Maybe<Scalars['String']>;
  MeterNumber?: Maybe<Scalars['String']>;
  Provider?: Maybe<Scalars['String']>;
};

export enum MeterVerificationStatusEnum {
  FAILED = 'failed',
  SUCCESS = 'success'
}

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  addEducation: Scalars['String'];
  addEmployment: Scalars['String'];
  addMedicalRecord: Scalars['String'];
  addTravelRecord?: Maybe<Scalars['String']>;
  addUser?: Maybe<SignedUpUser>;
  approveProfileRequest?: Maybe<Scalars['String']>;
  businessApproveAppointment?: Maybe<Scalars['String']>;
  businessArchiveStaff?: Maybe<Scalars['String']>;
  businessArchiveVisitorAppointment?: Maybe<Scalars['String']>;
  businessCancelVisitorAppointment?: Maybe<Scalars['String']>;
  businessCheckInVisitorWithOneID?: Maybe<Scalars['String']>;
  businessCheckoutStaffAttendance?: Maybe<Scalars['String']>;
  businessCreateAccount: Scalars['String'];
  businessCreateAdmin?: Maybe<Scalars['String']>;
  businessCreateAttendance?: Maybe<Scalars['String']>;
  businessCreateCustomer?: Maybe<Scalars['String']>;
  businessCreateCustomerAttendance?: Maybe<Scalars['String']>;
  businessCreateCustomerWithOneID?: Maybe<Scalars['String']>;
  businessCreateStaff?: Maybe<Staff>;
  businessCreateStaffCard?: Maybe<Scalars['String']>;
  businessCustomerAttendanceCheckout?: Maybe<Scalars['String']>;
  businessDeleteAccount: Scalars['String'];
  businessDeleteAdmin?: Maybe<Scalars['String']>;
  businessDeleteCustomer?: Maybe<Scalars['String']>;
  businessDeleteStaff?: Maybe<Scalars['String']>;
  businessDeleteStaffCard?: Maybe<Scalars['String']>;
  businessDeleteVisitor?: Maybe<Scalars['String']>;
  businessDisableAdmin?: Maybe<Scalars['String']>;
  businessLogin: AuthUserResponse;
  businessLoginAdmin?: Maybe<AuthUserResponse>;
  businessRescheduleVisitorAppointment?: Maybe<Scalars['String']>;
  businessResendEmailActivation: Scalars['String'];
  businessResetPassword: Scalars['String'];
  businessScheduleAppointment?: Maybe<Scalars['String']>;
  businessScheduleAppointmentAgain?: Maybe<Scalars['String']>;
  businessScheduleAppointmentWithOneID?: Maybe<Scalars['String']>;
  businessScheduleAppointmentWithOneIDWithoutAuth?: Maybe<Scalars['String']>;
  businessScheduleAppointmentWithoutAuth?: Maybe<Scalars['String']>;
  businessSetAdminProfileImage?: Maybe<Scalars['String']>;
  businessToggleAppointmentApproval: Scalars['String'];
  businessToggleStaffActivation?: Maybe<Scalars['String']>;
  businessToggleStaffCardStatus?: Maybe<Scalars['String']>;
  businessToggleVisitorVerification: Scalars['String'];
  businessUpdateAdmin?: Maybe<Scalars['String']>;
  businessUpdateAdminPassword?: Maybe<Scalars['String']>;
  businessUpdateLogo?: Maybe<Scalars['String']>;
  businessUpdateProfile?: Maybe<Scalars['String']>;
  businessVerifyEmail: Scalars['String'];
  businessVerifyOneIDWithToken?: Maybe<Scalars['String']>;
  businessVisitorAppointmentCheckIn?: Maybe<Scalars['String']>;
  businessVisitorAttendanceCheckout?: Maybe<Scalars['String']>;
  businessVisitorVerificationWithToken?: Maybe<Scalars['String']>;
  bvnVerify?: Maybe<Scalars['JSON']>;
  cacVerify?: Maybe<Scalars['JSON']>;
  cancelTravel: Scalars['String'];
  changePassword?: Maybe<Scalars['String']>;
  createChat?: Maybe<Chat>;
  createDeveloperBusinessProfile?: Maybe<DeveloperConsoleProfile>;
  deleteApiKey?: Maybe<Scalars['String']>;
  deleteChat: Scalars['String'];
  deleteDocument: Scalars['Boolean'];
  deleteEducation: Scalars['String'];
  deleteEmployment: Scalars['String'];
  deleteMedical: Scalars['String'];
  deleteTravelRecord: Scalars['String'];
  deleteUser?: Maybe<Scalars['String']>;
  disableMFA?: Maybe<MfaResponse>;
  documentAnalysis?: Maybe<Scalars['JSON']>;
  editApiKey?: Maybe<ApiKey>;
  enableMFA?: Maybe<MfaResponse>;
  flagActivity?: Maybe<Scalars['String']>;
  generateApiKey?: Maybe<ApiKey>;
  generateBusinessScheduleMeetingUrl?: Maybe<Scalars['String']>;
  generateTransaction?: Maybe<Wallet>;
  loginAuthWithOneId?: Maybe<Scalars['JSON']>;
  loginUser: AuthUserResponse;
  meterNumberAddressVerify?: Maybe<MeterDetails>;
  ninVerify?: Maybe<Scalars['JSON']>;
  notifyMe?: Maybe<Scalars['String']>;
  phoneAdvancedVerify?: Maybe<Scalars['JSON']>;
  phoneBasicVerify?: Maybe<Scalars['JSON']>;
  profileLooker?: Maybe<Scalars['String']>;
  readActivity?: Maybe<Scalars['String']>;
  register2FA?: Maybe<Register2FaResponse>;
  rejectProfileRequest?: Maybe<Scalars['String']>;
  resendActivationCode: Scalars['String'];
  resendEmailActivation: Scalars['String'];
  resendMfaLoginCode?: Maybe<Scalars['String']>;
  resetPassword: Scalars['String'];
  sendDisableMFACode?: Maybe<Scalars['Boolean']>;
  setBusinessProfileLogo: Scalars['String'];
  setProfileImage: Scalars['String'];
  signUpAuthWithOneId?: Maybe<Scalars['JSON']>;
  updateChat?: Maybe<Chat>;
  updateConsoleEnv?: Maybe<DeveloperConsoleProfile>;
  updateDeveloperBusinessProfile?: Maybe<DeveloperConsoleProfile>;
  updateEmployment: Scalars['String'];
  updateMedicalRecord: Scalars['String'];
  updatePrivacyOptions: PrivacyOptionsResponse;
  updateShowTourGuide?: Maybe<Scalars['Boolean']>;
  updateSocialMedia: Scalars['String'];
  updateTravelRecord: Scalars['String'];
  updateUser?: Maybe<Scalars['String']>;
  uploadDocument: Scalars['String'];
  userAddCriminalRecord: Scalars['String'];
  userAddEmergencyContact: Scalars['String'];
  userChangeCriminalStatus: Scalars['String'];
  userCreateProfile: Scalars['String'];
  userDeleteCriminalRecord: Scalars['String'];
  userDeleteEmergencyContact: Scalars['String'];
  userRevokeAccesss?: Maybe<Scalars['String']>;
  userRevokeConnectedService: Scalars['String'];
  userUpdateEmergencyContact: Scalars['String'];
  validate2FA?: Maybe<Scalars['Boolean']>;
  verify2FA?: Maybe<Verify2FaResponse>;
  verifyActivationCode: Scalars['String'];
  verifyBusinessRCNumber?: Maybe<DeveloperConsoleProfile>;
  verifyBvn?: Maybe<Scalars['JSON']>;
  verifyCac?: Maybe<Scalars['JSON']>;
  verifyDocument?: Maybe<Scalars['JSON']>;
  verifyDocumentWithSelfie?: Maybe<Scalars['JSON']>;
  verifyEmail: Scalars['String'];
  verifyMeterNumber?: Maybe<MeterDetails>;
  verifyNin?: Maybe<Scalars['JSON']>;
  verifyPhoneAdvanced?: Maybe<Scalars['JSON']>;
  verifyPhoneBasic?: Maybe<Scalars['JSON']>;
};


export type MutationAddEducationArgs = {
  data: AddEducationInput;
};


export type MutationAddEmploymentArgs = {
  data: AddEmploymentInput;
};


export type MutationAddMedicalRecordArgs = {
  data: AddMedicalInput;
};


export type MutationAddTravelRecordArgs = {
  data: AddTravelRecordInput;
};


export type MutationAddUserArgs = {
  data: UserInput;
};


export type MutationApproveProfileRequestArgs = {
  requestId: Scalars['ID'];
};


export type MutationBusinessApproveAppointmentArgs = {
  data: BusinessAppointmentInput;
};


export type MutationBusinessArchiveStaffArgs = {
  staffID: Scalars['ID'];
};


export type MutationBusinessArchiveVisitorAppointmentArgs = {
  data: BusinessAppointmentInput;
};


export type MutationBusinessCancelVisitorAppointmentArgs = {
  data: BusinessAppointmentInput;
};


export type MutationBusinessCheckInVisitorWithOneIdArgs = {
  data: BusinessCheckInVisitorWithOneIdInput;
};


export type MutationBusinessCheckoutStaffAttendanceArgs = {
  data: BusinessCheckoutStaffAttendanceInput;
};


export type MutationBusinessCreateAccountArgs = {
  data: AddBusinessInput;
};


export type MutationBusinessCreateAdminArgs = {
  data: BusinessCreateAdminInput;
};


export type MutationBusinessCreateAttendanceArgs = {
  data: CreateAttendanceInput;
};


export type MutationBusinessCreateCustomerArgs = {
  data: BusinessCreateCustomerInput;
};


export type MutationBusinessCreateCustomerAttendanceArgs = {
  data: BusinessCreateCustomerAttendanceInput;
};


export type MutationBusinessCreateCustomerWithOneIdArgs = {
  data?: InputMaybe<BusinessCreateCustomerWithOneIdInput>;
};


export type MutationBusinessCreateStaffArgs = {
  data: CreateStaffInput;
};


export type MutationBusinessCreateStaffCardArgs = {
  data?: InputMaybe<BusinessCreateStaffCardInput>;
};


export type MutationBusinessCustomerAttendanceCheckoutArgs = {
  data: CustomerAttendanceCheckoutInput;
};


export type MutationBusinessDeleteAdminArgs = {
  adminID: Scalars['ID'];
};


export type MutationBusinessDeleteCustomerArgs = {
  customerID: Scalars['ID'];
};


export type MutationBusinessDeleteStaffArgs = {
  data: DeleteStaffInput;
};


export type MutationBusinessDeleteStaffCardArgs = {
  data: StaffCardInput;
};


export type MutationBusinessDeleteVisitorArgs = {
  visitorID: Scalars['ID'];
};


export type MutationBusinessDisableAdminArgs = {
  adminID: Scalars['ID'];
};


export type MutationBusinessLoginArgs = {
  data: LoginBusinessInput;
};


export type MutationBusinessLoginAdminArgs = {
  data?: InputMaybe<AdminLoginInput>;
};


export type MutationBusinessRescheduleVisitorAppointmentArgs = {
  data: BusinessRescheduleVisitorAppointmentInput;
};


export type MutationBusinessResendEmailActivationArgs = {
  businessEmail: Scalars['String'];
};


export type MutationBusinessResetPasswordArgs = {
  data: ResetPassword;
};


export type MutationBusinessScheduleAppointmentArgs = {
  data: BusinessScheduleVisitorInput;
};


export type MutationBusinessScheduleAppointmentAgainArgs = {
  data: BusinessScheduleAppointmentAgainInput;
};


export type MutationBusinessScheduleAppointmentWithOneIdArgs = {
  data: BusinessScheduleAppointmentWithOneIdInput;
};


export type MutationBusinessScheduleAppointmentWithOneIdWithoutAuthArgs = {
  data: BusinessScheduleAppointmentWithOneIdWithoutAuthInput;
};


export type MutationBusinessScheduleAppointmentWithoutAuthArgs = {
  data: BusinessScheduleAppointmentWithoutAuthInput;
};


export type MutationBusinessSetAdminProfileImageArgs = {
  data: UpdateAdminProfileImage;
};


export type MutationBusinessToggleStaffActivationArgs = {
  data: ToggleStaffActivationInput;
};


export type MutationBusinessToggleStaffCardStatusArgs = {
  data: StaffCardInput;
};


export type MutationBusinessUpdateAdminArgs = {
  data: BusinessUpdateAdminInput;
};


export type MutationBusinessUpdateAdminPasswordArgs = {
  data?: InputMaybe<AdminUpdatePasswordInput>;
};


export type MutationBusinessUpdateLogoArgs = {
  logo: Scalars['Upload'];
};


export type MutationBusinessUpdateProfileArgs = {
  data: UpdateBusinessProfileInput;
};


export type MutationBusinessVerifyEmailArgs = {
  emailToken: Scalars['String'];
};


export type MutationBusinessVerifyOneIdWithTokenArgs = {
  data: BusinessVerifyOneIdWithTokenInput;
};


export type MutationBusinessVisitorAppointmentCheckInArgs = {
  data: BusinessAppointmentInput;
};


export type MutationBusinessVisitorAttendanceCheckoutArgs = {
  data: BusinessVisitorAttendanceCheckoutInput;
};


export type MutationBusinessVisitorVerificationWithTokenArgs = {
  data: BusinessVisitorVerificationWithTokenInput;
};


export type MutationBvnVerifyArgs = {
  data?: InputMaybe<Bvn>;
};


export type MutationCacVerifyArgs = {
  data?: InputMaybe<Cac>;
};


export type MutationCancelTravelArgs = {
  data?: InputMaybe<CancelTravelInput>;
};


export type MutationChangePasswordArgs = {
  data?: InputMaybe<ChangePassword>;
};


export type MutationCreateChatArgs = {
  data: ChatInput;
};


export type MutationCreateDeveloperBusinessProfileArgs = {
  data: CreateDeveloperBusinessProfileInput;
};


export type MutationDeleteApiKeyArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteChatArgs = {
  chatId: Scalars['ID'];
};


export type MutationDeleteDocumentArgs = {
  documentId: Scalars['ID'];
};


export type MutationDeleteEducationArgs = {
  _id: Scalars['ID'];
};


export type MutationDeleteEmploymentArgs = {
  _id: Scalars['ID'];
};


export type MutationDeleteMedicalArgs = {
  _id: Scalars['ID'];
};


export type MutationDeleteTravelRecordArgs = {
  _id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  _id: Scalars['ID'];
};


export type MutationDisableMfaArgs = {
  disableMfaCode: Scalars['String'];
};


export type MutationDocumentAnalysisArgs = {
  data: DocumentAnalysisInput;
};


export type MutationEditApiKeyArgs = {
  data: EditKeyData;
};


export type MutationEnableMfaArgs = {
  mfaType: MfaType;
};


export type MutationFlagActivityArgs = {
  activityID: Scalars['ID'];
};


export type MutationGenerateApiKeyArgs = {
  data: CreateApiKeyData;
};


export type MutationGenerateTransactionArgs = {
  data: GenerateTransactionData;
};


export type MutationLoginAuthWithOneIdArgs = {
  data: LoginAuthWithOneIdInput;
};


export type MutationLoginUserArgs = {
  data: UserLogin;
};


export type MutationMeterNumberAddressVerifyArgs = {
  data?: InputMaybe<VerifyMeter>;
};


export type MutationNinVerifyArgs = {
  data?: InputMaybe<Nin>;
};


export type MutationNotifyMeArgs = {
  data: NotifyMeInput;
};


export type MutationPhoneAdvancedVerifyArgs = {
  data?: InputMaybe<Phone>;
};


export type MutationPhoneBasicVerifyArgs = {
  data?: InputMaybe<Phone>;
};


export type MutationProfileLookerArgs = {
  data?: InputMaybe<ProfileLookerInputs>;
};


export type MutationReadActivityArgs = {
  activityID: Scalars['ID'];
};


export type MutationRejectProfileRequestArgs = {
  requestId: Scalars['ID'];
};


export type MutationResendActivationCodeArgs = {
  email: Scalars['String'];
};


export type MutationResendEmailActivationArgs = {
  email: Scalars['String'];
};


export type MutationResendMfaLoginCodeArgs = {
  data?: InputMaybe<ResendMfaLoginCodeInput>;
};


export type MutationResetPasswordArgs = {
  data: ResetPassword;
};


export type MutationSetBusinessProfileLogoArgs = {
  businessLogo: Scalars['Upload'];
};


export type MutationSetProfileImageArgs = {
  profileImage: Scalars['Upload'];
};


export type MutationSignUpAuthWithOneIdArgs = {
  data: SignUpAuthWithOneIdInput;
};


export type MutationUpdateChatArgs = {
  data: UpdateChatInput;
};


export type MutationUpdateConsoleEnvArgs = {
  environment: ConsoleEnv;
};


export type MutationUpdateDeveloperBusinessProfileArgs = {
  data: UpdateDeveloperBusinessProfileInput;
};


export type MutationUpdateEmploymentArgs = {
  data: AddEmploymentInput;
  recordId: Scalars['ID'];
};


export type MutationUpdateMedicalRecordArgs = {
  data: AddMedicalInput;
};


export type MutationUpdatePrivacyOptionsArgs = {
  data: PrivacyOptionsInput;
};


export type MutationUpdateSocialMediaArgs = {
  data: SocialMediaInputs;
};


export type MutationUpdateTravelRecordArgs = {
  data?: InputMaybe<UpdateTravelRecordInput>;
};


export type MutationUpdateUserArgs = {
  data?: InputMaybe<UpdateUser>;
};


export type MutationUploadDocumentArgs = {
  data?: InputMaybe<DocumentInput>;
};


export type MutationUserAddCriminalRecordArgs = {
  data?: InputMaybe<AddCriminalRecordInput>;
};


export type MutationUserAddEmergencyContactArgs = {
  data: EmergencyContactInput;
};


export type MutationUserChangeCriminalStatusArgs = {
  data?: InputMaybe<ChangeCriminalStatusInput>;
};


export type MutationUserCreateProfileArgs = {
  data: AddProfile;
};


export type MutationUserDeleteCriminalRecordArgs = {
  recordID: Scalars['ID'];
};


export type MutationUserDeleteEmergencyContactArgs = {
  data: DeleteEmergencyContact;
};


export type MutationUserRevokeAccesssArgs = {
  userId: Scalars['ID'];
};


export type MutationUserRevokeConnectedServiceArgs = {
  businessID: Scalars['ID'];
};


export type MutationUserUpdateEmergencyContactArgs = {
  data: UpdateEmergencyContact;
};


export type MutationValidate2FaArgs = {
  token: Scalars['String'];
};


export type MutationVerify2FaArgs = {
  token: Scalars['String'];
};


export type MutationVerifyActivationCodeArgs = {
  activationCode: Scalars['String'];
};


export type MutationVerifyBusinessRcNumberArgs = {
  data: VerifyBusinessRcNumberInput;
};


export type MutationVerifyBvnArgs = {
  data?: InputMaybe<Bvn>;
};


export type MutationVerifyCacArgs = {
  data?: InputMaybe<Cac>;
};


export type MutationVerifyDocumentArgs = {
  document: Scalars['Upload'];
};


export type MutationVerifyDocumentWithSelfieArgs = {
  data: DocVerifyWithSelfie;
};


export type MutationVerifyEmailArgs = {
  emailToken: Scalars['String'];
};


export type MutationVerifyMeterNumberArgs = {
  data?: InputMaybe<VerifyMeter>;
};


export type MutationVerifyNinArgs = {
  data?: InputMaybe<Nin>;
};


export type MutationVerifyPhoneAdvancedArgs = {
  data?: InputMaybe<Phone>;
};


export type MutationVerifyPhoneBasicArgs = {
  data?: InputMaybe<Phone>;
};

export type NextOfKin = {
  __typename?: 'NextOfKin';
  address?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  PAID = 'paid',
  PENDING = 'pending'
}

export enum PeriodEnum {
  MONTH = 'month',
  WEEK = 'week',
  YEAR = 'year'
}

export type PrivacyOptions = {
  __typename?: 'PrivacyOptions';
  billing?: Maybe<Scalars['Boolean']>;
  education?: Maybe<Scalars['Boolean']>;
  emergency?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  kyc_bvn?: Maybe<Scalars['Boolean']>;
  kyc_nin?: Maybe<Scalars['Boolean']>;
  medical?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<Scalars['Boolean']>;
  nextOfKin?: Maybe<Scalars['Boolean']>;
  personal?: Maybe<Scalars['Boolean']>;
  phoneVerification?: Maybe<Scalars['Boolean']>;
  travel?: Maybe<Scalars['Boolean']>;
  workExperience?: Maybe<Scalars['Boolean']>;
};

export type PrivacyOptionsResponse = {
  __typename?: 'PrivacyOptionsResponse';
  message?: Maybe<Scalars['String']>;
  privacyOptions?: Maybe<PrivacyOptions>;
};

export type Profile = {
  __typename?: 'Profile';
  _id?: Maybe<Scalars['ID']>;
  bvn?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  developerConsoleProfile?: Maybe<DeveloperConsoleProfile>;
  dob?: Maybe<Scalars['DateTime']>;
  emergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  employmentStatus?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  kycTypes?: Maybe<Array<Maybe<KycType>>>;
  kycVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lga?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  nextOfKin?: Maybe<NextOfKin>;
  nin?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  secondaryAddress?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  socialMedia?: Maybe<SocialMedia>;
  stateOfOrigin?: Maybe<Scalars['String']>;
  stateOfResidence?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  verificationLevel?: Maybe<Scalars['Int']>;
};

export type ProfileLookupNotification = {
  __typename?: 'ProfileLookupNotification';
  createdAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
};

export type ProfileMeta = {
  __typename?: 'ProfileMeta';
  _id?: Maybe<Scalars['ID']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<ScopeOutput>;
  url?: Maybe<Scalars['String']>;
};

export type QueriedProfile = {
  __typename?: 'QueriedProfile';
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  education?: Maybe<Education>;
  email?: Maybe<Scalars['String']>;
  employment?: Maybe<Employment>;
  isVerified?: Maybe<Scalars['Boolean']>;
  medical?: Maybe<Medical>;
  oneId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  travel?: Maybe<Travel>;
  username?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  businessForgotPassword: Scalars['String'];
  businessGetAccount?: Maybe<BusinessAccount>;
  businessGetAccounts?: Maybe<Array<Maybe<BusinessAccount>>>;
  businessGetAdmin?: Maybe<BusinessAdmin>;
  businessGetAllActiveVisitorsAppointment?: Maybe<Array<Maybe<VisitorAppointment>>>;
  businessGetAllAdmins?: Maybe<Array<Maybe<BusinessAdmin>>>;
  businessGetAllArchivedVisitorsAppointment?: Maybe<Array<Maybe<VisitorAppointment>>>;
  businessGetAllAttendance?: Maybe<Array<Maybe<StaffAttendance>>>;
  businessGetAllCancelledVisitorsAppointment?: Maybe<Array<Maybe<VisitorAppointment>>>;
  businessGetAllCheckedInAppointments?: Maybe<Array<Maybe<VisitorAppointment>>>;
  businessGetAllCustomers?: Maybe<Array<Maybe<BusinessCustomer>>>;
  businessGetAllCustomersAttendance?: Maybe<Array<Maybe<CustomerAttendance>>>;
  businessGetAllStaff?: Maybe<Array<Maybe<Staff>>>;
  businessGetAllStaffCards?: Maybe<Array<Maybe<StaffCard>>>;
  businessGetAllVisitors?: Maybe<Array<Maybe<BusinessVisitor>>>;
  businessGetAllVisitorsAppointment?: Maybe<Array<Maybe<VisitorAppointment>>>;
  businessGetAllVisitorsAttendance?: Maybe<Array<Maybe<VisitorAttendance>>>;
  businessGetCurrentUser?: Maybe<BusinessAdmin>;
  businessGetCustomer?: Maybe<BusinessCustomer>;
  businessGetProfile?: Maybe<BusinessAccount>;
  businessGetStaff?: Maybe<Staff>;
  businessGetStaffAttendance?: Maybe<StaffAttendance>;
  businessGetStaffAttendances?: Maybe<Array<Maybe<StaffAttendance>>>;
  businessGetStaffCard?: Maybe<StaffCard>;
  businessGetStaffCount?: Maybe<Scalars['Int']>;
  businessGetTodaysCustomers?: Maybe<Scalars['Int']>;
  businessGetTodaysStaff?: Maybe<Scalars['Int']>;
  businessGetTodaysVisitors?: Maybe<Scalars['Int']>;
  businessGetVisitor?: Maybe<BusinessVisitor>;
  businessResendEmailVerification: Scalars['String'];
  businessSendEmailVerification: Scalars['String'];
  forgotPassword: Scalars['String'];
  getActivity?: Maybe<Activity>;
  getAllActivities?: Maybe<Array<Maybe<Activity>>>;
  getAllApiKey?: Maybe<Array<Maybe<ApiKey>>>;
  getAllAppointmentPass?: Maybe<Array<Maybe<AppointmentPass>>>;
  getAllAppointments?: Maybe<Array<Maybe<BusinessVisitorsAppointment>>>;
  getAllCancelledTravels?: Maybe<Array<Maybe<Travel>>>;
  getAllMedicalRecords?: Maybe<Array<Maybe<Medical>>>;
  getAllTravelRecords?: Maybe<Array<Maybe<Travel>>>;
  getApiKey?: Maybe<ApiKey>;
  getApiKeyDashboardSummaryStats?: Maybe<ApiKeyDashboardSummaryStatsOutPut>;
  getApiKeyFailedCalls?: Maybe<Array<Maybe<ApiKeyHistory>>>;
  getApiKeyFailedCallsForUser?: Maybe<Array<Maybe<ApiKeyHistory>>>;
  getApiKeyFailedCount?: Maybe<Scalars['Int']>;
  getApiKeyFailedCountForUser?: Maybe<Scalars['Int']>;
  getApiKeyHistory?: Maybe<Array<Maybe<ApiKeyHistory>>>;
  getApiKeyScopes?: Maybe<Array<Maybe<ForEnum>>>;
  getApiKeySuccessCalls?: Maybe<Array<Maybe<ApiKeyHistory>>>;
  getApiKeySuccessCallsForUser?: Maybe<Array<Maybe<ApiKeyHistory>>>;
  getApiKeySuccessCount?: Maybe<Scalars['Int']>;
  getApiKeySuccessCountForUser?: Maybe<Scalars['Int']>;
  getApiKeyTotalCount?: Maybe<Scalars['Int']>;
  getApiKeyTotalCountForUser?: Maybe<Scalars['Int']>;
  getAppointment?: Maybe<BusinessVisitorsAppointment>;
  getAppointmentPass?: Maybe<AppointmentPass>;
  getBillDetails?: Maybe<Array<Maybe<ApiKeyHistory>>>;
  getBillInvoice?: Maybe<Scalars['String']>;
  getBillPeriodInvoice?: Maybe<Invoice>;
  getBillPeriodInvoicePDF?: Maybe<Scalars['JSON']>;
  getBillReceipt?: Maybe<Scalars['String']>;
  getBillingHistoryForApiKey?: Maybe<Array<Maybe<Bills>>>;
  getBillingHistoryForUser?: Maybe<Array<Maybe<Bills>>>;
  getChat?: Maybe<Chat>;
  getChats?: Maybe<Array<Maybe<Chat>>>;
  getCountries?: Maybe<Array<Maybe<CountryList>>>;
  getCurrentUser?: Maybe<User>;
  getDeveloperConsoleProfile?: Maybe<DeveloperConsoleProfile>;
  getEducation?: Maybe<Education>;
  getEducationRecords?: Maybe<Array<Maybe<Education>>>;
  getEmployment?: Maybe<Employment>;
  getEmployments?: Maybe<Array<Maybe<Employment>>>;
  getInvoices?: Maybe<Array<Maybe<Invoice>>>;
  getLGA?: Maybe<Array<Maybe<LgaList>>>;
  getLatestAppointments?: Maybe<Array<Maybe<BusinessVisitorsAppointment>>>;
  getMedicalRecord?: Maybe<Medical>;
  getMeterNumberVerifications?: Maybe<Array<Maybe<MeterNumberVerifications>>>;
  getProfileMeta?: Maybe<ProfileMeta>;
  getProfileRequestById?: Maybe<Scalars['JSON']>;
  getStaffCard?: Maybe<StaffCard>;
  getStaffCards?: Maybe<Array<Maybe<StaffCard>>>;
  getStates?: Maybe<Array<Maybe<StatesList>>>;
  getTransactions?: Maybe<Array<Maybe<Wallet>>>;
  getTravelRecord?: Maybe<Travel>;
  getUnpaidBillForApiKey?: Maybe<Scalars['JSON']>;
  getUser?: Maybe<User>;
  getUserApikeysHistory?: Maybe<Array<Maybe<ApiKeyHistoryMix>>>;
  getUserApikeysUsageGraph?: Maybe<Array<Maybe<ApiKeyHistoryGraph>>>;
  getUserBillsDetails?: Maybe<Array<Maybe<ApiKeyHistory>>>;
  getUserBillsEstimatedCost?: Maybe<BillsEstimate>;
  getUserCancelledTravels?: Maybe<Array<Maybe<Travel>>>;
  getUserDocuments?: Maybe<Array<Maybe<Document>>>;
  getUserEducations?: Maybe<Array<Maybe<Education>>>;
  getUserEmployments?: Maybe<Array<Maybe<Employment>>>;
  getUserMedicalRecords?: Maybe<Array<Maybe<Medical>>>;
  getUserTravelRecords?: Maybe<Array<Maybe<Travel>>>;
  getUsers?: Maybe<Array<Maybe<User>>>;
  getWalletBalance?: Maybe<Scalars['Int']>;
  isNotifiedFor: Scalars['Boolean'];
  resendEmailVerification: Scalars['String'];
  sendEmailVerification: Scalars['String'];
  thirdPartyGetPendingProfileRequests?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  thirdPartyGetProfileRequests?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  userGetAccesssList?: Maybe<Scalars['JSON']>;
  userGetAllCriminalRecords?: Maybe<Array<Maybe<Criminal>>>;
  userGetAllEmergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  userGetAllProfileRequests?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  userGetAllProfiles?: Maybe<Array<Maybe<Profile>>>;
  userGetBVNVerificationStatus?: Maybe<Scalars['Boolean']>;
  userGetConnectedService?: Maybe<Scalars['JSON']>;
  userGetConnectedServices?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  userGetCriminalRecord?: Maybe<Criminal>;
  userGetCriminalStatus: Scalars['Boolean'];
  userGetEmergencyContact?: Maybe<EmergencyContact>;
  userGetEmergencyContacts?: Maybe<Array<Maybe<EmergencyContact>>>;
  userGetExConCriminals?: Maybe<Array<Maybe<Criminal>>>;
  userGetNINVerificationStatus?: Maybe<Scalars['Boolean']>;
  userGetProfile?: Maybe<Profile>;
  userGetProfileRequest?: Maybe<Scalars['JSON']>;
  userGetProfileRequests?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  userGetServingCriminals?: Maybe<Array<Maybe<Criminal>>>;
  userGetVerifiedProfiles?: Maybe<Array<Maybe<User>>>;
  userQueryOneId?: Maybe<Scalars['JSON']>;
};


export type QueryBusinessForgotPasswordArgs = {
  businessEmail: Scalars['String'];
};


export type QueryBusinessGetAccountArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessGetAccountsArgs = {
  filter?: InputMaybe<UserFilterInput>;
};


export type QueryBusinessGetAdminArgs = {
  adminID: Scalars['ID'];
};


export type QueryBusinessGetAllStaffCardsArgs = {
  businessID: Scalars['ID'];
};


export type QueryBusinessGetCustomerArgs = {
  data?: InputMaybe<GetBusinessCustomerInput>;
};


export type QueryBusinessGetStaffArgs = {
  staffID: Scalars['ID'];
};


export type QueryBusinessGetStaffAttendanceArgs = {
  data: AttendanceInput;
};


export type QueryBusinessGetStaffAttendancesArgs = {
  staffID: Scalars['ID'];
};


export type QueryBusinessGetStaffCardArgs = {
  data?: InputMaybe<StaffCardInput>;
};


export type QueryBusinessGetVisitorArgs = {
  data: GetBusinessVisitorInput;
};


export type QueryBusinessResendEmailVerificationArgs = {
  businessEmail: Scalars['String'];
};


export type QueryBusinessSendEmailVerificationArgs = {
  businessId: Scalars['ID'];
};


export type QueryForgotPasswordArgs = {
  email: Scalars['String'];
  isMobile?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetActivityArgs = {
  activityID: Scalars['ID'];
};


export type QueryGetAllApiKeyArgs = {
  filter?: InputMaybe<Filter>;
};


export type QueryGetAllCancelledTravelsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryGetAllMedicalRecordsArgs = {
  filter: GetFilters;
};


export type QueryGetAllTravelRecordsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryGetApiKeyArgs = {
  id: Scalars['ID'];
};


export type QueryGetApiKeyDashboardSummaryStatsArgs = {
  environment: ConsoleEnv;
};


export type QueryGetApiKeyFailedCallsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetApiKeyFailedCountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetApiKeyHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryGetApiKeySuccessCallsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetApiKeySuccessCountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetApiKeyTotalCountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetAppointmentPassArgs = {
  passId: Scalars['ID'];
};


export type QueryGetBillDetailsArgs = {
  BillId: Scalars['ID'];
};


export type QueryGetBillInvoiceArgs = {
  BillId: Scalars['ID'];
};


export type QueryGetBillReceiptArgs = {
  InvoiceId: Scalars['ID'];
};


export type QueryGetBillingHistoryForApiKeyArgs = {
  apikeyId: Scalars['ID'];
};


export type QueryGetChatArgs = {
  chatId: Scalars['ID'];
};


export type QueryGetEducationArgs = {
  _id: Scalars['ID'];
};


export type QueryGetEducationRecordsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryGetEmploymentArgs = {
  _id: Scalars['ID'];
};


export type QueryGetEmploymentsArgs = {
  filter: GetFilters;
};


export type QueryGetLgaArgs = {
  geonameId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMedicalRecordArgs = {
  _id: Scalars['ID'];
};


export type QueryGetProfileMetaArgs = {
  api_key: Scalars['String'];
};


export type QueryGetProfileRequestByIdArgs = {
  requestId: Scalars['ID'];
};


export type QueryGetStaffCardArgs = {
  cardID: Scalars['ID'];
};


export type QueryGetStatesArgs = {
  geonameId: Scalars['String'];
};


export type QueryGetTransactionsArgs = {
  data: TransactionsData;
};


export type QueryGetTravelRecordArgs = {
  _id: Scalars['ID'];
};


export type QueryGetUnpaidBillForApiKeyArgs = {
  apikeyId: Scalars['ID'];
};


export type QueryGetUserArgs = {
  _id: Scalars['ID'];
};


export type QueryGetUserApikeysHistoryArgs = {
  filter?: InputMaybe<GraphFilter>;
};


export type QueryGetUserApikeysUsageGraphArgs = {
  filter?: InputMaybe<GraphFilter>;
};


export type QueryGetUserCancelledTravelsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryGetUserEducationsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryGetUserEmploymentsArgs = {
  filter: GetFilters;
};


export type QueryGetUserMedicalRecordsArgs = {
  filter: GetFilters;
};


export type QueryGetUserTravelRecordsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryGetUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
};


export type QueryGetWalletBalanceArgs = {
  data: BalanceData;
};


export type QueryIsNotifiedForArgs = {
  data: NotifyMeInput;
};


export type QueryResendEmailVerificationArgs = {
  email: Scalars['String'];
};


export type QuerySendEmailVerificationArgs = {
  _id: Scalars['ID'];
};


export type QueryThirdPartyGetPendingProfileRequestsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryThirdPartyGetProfileRequestsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetAllCriminalRecordsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetAllEmergencyContactsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetAllProfileRequestsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetAllProfilesArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetBvnVerificationStatusArgs = {
  profileId: Scalars['ID'];
};


export type QueryUserGetConnectedServiceArgs = {
  serviceID: Scalars['ID'];
};


export type QueryUserGetConnectedServicesArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetCriminalRecordArgs = {
  data: GetCriminalRecordInput;
};


export type QueryUserGetCriminalStatusArgs = {
  data: GetCriminalRecordInput;
};


export type QueryUserGetEmergencyContactArgs = {
  _id: Scalars['ID'];
};


export type QueryUserGetExConCriminalsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetNinVerificationStatusArgs = {
  profileId: Scalars['ID'];
};


export type QueryUserGetProfileRequestArgs = {
  requestId: Scalars['ID'];
};


export type QueryUserGetProfileRequestsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetServingCriminalsArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserGetVerifiedProfilesArgs = {
  filter?: InputMaybe<GetFilters>;
};


export type QueryUserQueryOneIdArgs = {
  oneIDorEmail: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
};

export type RecentDevice = {
  __typename?: 'RecentDevice';
  _id?: Maybe<Scalars['ID']>;
  client?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type Register2FaResponse = {
  __typename?: 'Register2FAResponse';
  otpAuthUrl?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
};

export type ResetPassword = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type ScopeInput = {
  for: Array<InputMaybe<ForInput>>;
};

export type ScopeOutput = {
  __typename?: 'ScopeOutput';
  for?: Maybe<Array<Maybe<ForOutput>>>;
};

export type SignedUpUser = {
  __typename?: 'SignedUpUser';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type SocialMedia = {
  __typename?: 'SocialMedia';
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  snapchat?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

export type Staff = {
  __typename?: 'Staff';
  _id?: Maybe<Scalars['ID']>;
  attendance?: Maybe<Array<Maybe<StaffAttendance>>>;
  businessEngagements?: Maybe<Array<Maybe<BusinessAccount>>>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  profiler?: Maybe<Scalars['JSON']>;
  staffCard?: Maybe<StaffCard>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type StaffActivity = {
  __typename?: 'StaffActivity';
  _id?: Maybe<Scalars['ID']>;
  activity?: Maybe<Scalars['String']>;
  businessID?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  staff?: Maybe<Staff>;
  typeOf?: Maybe<Scalars['String']>;
};

export type StaffAttendance = {
  __typename?: 'StaffAttendance';
  _id?: Maybe<Scalars['ID']>;
  businessID?: Maybe<Scalars['ID']>;
  checkIn?: Maybe<Scalars['DateTime']>;
  checkout?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<Scalars['String']>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  staff?: Maybe<Staff>;
  staffCode?: Maybe<Scalars['String']>;
  staffID?: Maybe<Scalars['ID']>;
};

export type StaffCard = {
  __typename?: 'StaffCard';
  _id?: Maybe<Scalars['ID']>;
  businessDetails?: Maybe<BusinessAccount>;
  cardStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  isCardActive?: Maybe<Scalars['Boolean']>;
  staffDetails?: Maybe<Staff>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StatesList = {
  __typename?: 'StatesList';
  geonameId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum Status {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  INACTIVE = 'inactive'
}

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']>;
  chatCreated?: Maybe<Chat>;
  chatDeleted?: Maybe<Scalars['String']>;
  chatUpdated?: Maybe<Chat>;
  profileLookup?: Maybe<ProfileLookupNotification>;
};


export type SubscriptionChatUpdatedArgs = {
  chatId: Scalars['ID'];
};


export type SubscriptionProfileLookupArgs = {
  userID: Scalars['ID'];
};

export type ToggleStaffActivationInput = {
  businessID: Scalars['ID'];
  staffID: Scalars['ID'];
};

export type TransactionsData = {
  currency: Scalars['String'];
  type?: InputMaybe<TypeEnum>;
  userid: Scalars['String'];
};

export type Travel = {
  __typename?: 'Travel';
  _id?: Maybe<Scalars['ID']>;
  cause: Scalars['String'];
  city: Scalars['String'];
  cost: Scalars['String'];
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  lodgingAddress: Scalars['String'];
  returnDate?: Maybe<Scalars['DateTime']>;
  transportMeans: Scalars['String'];
  travelDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
};

export enum TypeEnum {
  CREDIT = 'credit',
  DEBIT = 'debit'
}

export type UpdateChatInput = {
  _id: Scalars['ID'];
  message: Scalars['String'];
};

export type UploadStaffImage = {
  profileImage: Scalars['String'];
  staffID: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  education?: Maybe<Array<Maybe<Education>>>;
  email?: Maybe<Scalars['String']>;
  employments?: Maybe<Array<Maybe<Employment>>>;
  hasEnabled2FA?: Maybe<Scalars['Boolean']>;
  hasEnabledMFA?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  mfaType?: Maybe<MfaType>;
  oneId?: Maybe<Scalars['String']>;
  privacyOptions?: Maybe<PrivacyOptions>;
  profile?: Maybe<Profile>;
  role?: Maybe<Scalars['String']>;
  showTourGuide?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type UserAuth = {
  __typename?: 'UserAuth';
  email?: Maybe<Scalars['String']>;
  oneId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type UserInput = {
  domain?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  isMobile?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UserLogin = {
  identifier?: InputMaybe<Scalars['String']>;
  mfaLoginCode?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type Verify2FaResponse = {
  __typename?: 'Verify2FAResponse';
  message?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type VisitorAppointment = {
  __typename?: 'VisitorAppointment';
  _id?: Maybe<Scalars['ID']>;
  appointmentPass?: Maybe<AppointmentPass>;
  businessID?: Maybe<Scalars['ID']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  floorNumber?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  isElapsed?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tagNumber?: Maybe<Scalars['String']>;
  toSee?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorItems?: Maybe<Array<Maybe<Items>>>;
  visitationReason?: Maybe<Scalars['String']>;
  visitingUserId?: Maybe<Scalars['ID']>;
  visitor?: Maybe<BusinessVisitor>;
};

export type VisitorAttendance = {
  __typename?: 'VisitorAttendance';
  _id?: Maybe<Scalars['ID']>;
  appointment?: Maybe<VisitorAppointment>;
  businessID?: Maybe<Scalars['ID']>;
  checkIn?: Maybe<Scalars['DateTime']>;
  checkout?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  isScheduled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitor?: Maybe<BusinessVisitor>;
};

export type Wallet = {
  __typename?: 'Wallet';
  amount?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<TypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userid?: Maybe<Scalars['String']>;
};

export type AddBusinessInput = {
  businessEmail: Scalars['String'];
  businessName: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type AddCriminalRecordInput = {
  courtOrder?: InputMaybe<Scalars['String']>;
  jailTime?: InputMaybe<Scalars['String']>;
  jailTimeComplete?: InputMaybe<Scalars['Boolean']>;
  offence: Scalars['String'];
  policeOrder?: InputMaybe<Scalars['String']>;
  recordID?: InputMaybe<Scalars['ID']>;
  statement?: InputMaybe<Scalars['String']>;
};

export type AddEducationInput = {
  certification: Scalars['String'];
  course?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  grade?: InputMaybe<Scalars['String']>;
  institutionType?: InputMaybe<Scalars['String']>;
  recordID?: InputMaybe<Scalars['ID']>;
  schoolAddress?: InputMaybe<Scalars['String']>;
  schoolName: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type AddEmploymentInput = {
  appointmentYear: Scalars['DateTime'];
  companyAddress: Scalars['String'];
  companyName: Scalars['String'];
  companyWebsite?: InputMaybe<Scalars['String']>;
  office?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['String']>;
  terminationYear?: InputMaybe<Scalars['DateTime']>;
};

export type AddMedicalInput = {
  ailment: Scalars['String'];
  cause?: InputMaybe<Scalars['String']>;
  costOfService?: InputMaybe<Scalars['String']>;
  doctorName: Scalars['String'];
  hospital?: InputMaybe<Scalars['String']>;
  hospitalAddress?: InputMaybe<Scalars['String']>;
  lastVisit?: InputMaybe<Scalars['DateTime']>;
};

export type AddProfile = {
  bvn?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  fathersName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lga?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  mothersName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  nextOfKin?: InputMaybe<NextOfKinInput>;
  nin?: InputMaybe<Scalars['String']>;
  parentsAddress?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<Scalars['String']>;
  secondaryAddress?: InputMaybe<Scalars['String']>;
  secondaryEmail?: InputMaybe<Scalars['String']>;
  stateOfOrigin?: InputMaybe<Scalars['String']>;
  stateOfResidence?: InputMaybe<Scalars['String']>;
};

export type AddTravelRecordInput = {
  cause: Scalars['String'];
  city: Scalars['String'];
  cost: Scalars['String'];
  country: Scalars['String'];
  lodgingAddress: Scalars['String'];
  returnDate?: InputMaybe<Scalars['DateTime']>;
  transportMeans: Scalars['String'];
  travelDate: Scalars['DateTime'];
};

export type BalanceData = {
  currency?: InputMaybe<Scalars['String']>;
  userid?: InputMaybe<Scalars['String']>;
};

export type BusinessAppointmentCheckInInput = {
  appointmentID: Scalars['ID'];
  businessID: Scalars['ID'];
  visitorID: Scalars['ID'];
};

export type BusinessAppointmentInput = {
  appointmentID: Scalars['ID'];
  businessID: Scalars['ID'];
  oneID?: InputMaybe<Scalars['ID']>;
  visitorID: Scalars['ID'];
};

export type BusinessCheckInVisitorWithOneIdInput = {
  checkIn?: InputMaybe<Scalars['DateTime']>;
  visitationReason?: InputMaybe<Scalars['String']>;
  visitorOneID: Scalars['String'];
};

export type BusinessCheckoutStaffAttendanceInput = {
  businessID: Scalars['ID'];
  checkout?: InputMaybe<Scalars['DateTime']>;
  staffID: Scalars['ID'];
};

export type BusinessCreateAdminInput = {
  oneIDorEmail: Scalars['String'];
  role?: InputMaybe<Role>;
};

export type BusinessCreateCustomerAttendanceInput = {
  businessID: Scalars['ID'];
  checkIn?: InputMaybe<Scalars['DateTime']>;
  checkout?: InputMaybe<Scalars['DateTime']>;
  customerID: Scalars['ID'];
  customerOneID?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type BusinessCreateCustomerInput = {
  address?: InputMaybe<Scalars['String']>;
  customerID?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type BusinessCreateCustomerWithOneIdInput = {
  checkIn?: InputMaybe<Scalars['DateTime']>;
  checkout?: InputMaybe<Scalars['DateTime']>;
  customerOneID: Scalars['String'];
  withAttendance?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessCreateStaffCardInput = {
  staffID: Scalars['ID'];
};

export type BusinessCreateVisitorInput = {
  address?: InputMaybe<Scalars['String']>;
  businessID: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  visitationReason?: InputMaybe<Scalars['String']>;
  visitorID?: InputMaybe<Scalars['ID']>;
};

export type BusinessRescheduleVisitorAppointmentInput = {
  appointmentID: Scalars['ID'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  visitationReason?: InputMaybe<Scalars['String']>;
};

export type BusinessScheduleAppointmentAgainInput = {
  appointmentID: Scalars['ID'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  visitationReason?: InputMaybe<Scalars['String']>;
};

export type BusinessScheduleAppointmentWithOneIdInput = {
  endDate: Scalars['DateTime'];
  floorNumber?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagNumber?: InputMaybe<Scalars['String']>;
  toSee?: InputMaybe<Scalars['String']>;
  vendorItems?: InputMaybe<Array<InputMaybe<VendorItemsInput>>>;
  visitationReason?: InputMaybe<Scalars['String']>;
  visitorOneID: Scalars['String'];
};

export type BusinessScheduleAppointmentWithOneIdWithoutAuthInput = {
  BusinessOneId: Scalars['String'];
  endDate: Scalars['DateTime'];
  floorNumber?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagNumber?: InputMaybe<Scalars['String']>;
  toSee?: InputMaybe<Scalars['String']>;
  vendorItems?: InputMaybe<Array<InputMaybe<VendorItemsInput>>>;
  visitationReason?: InputMaybe<Scalars['String']>;
  visitorOneID: Scalars['String'];
};

export type BusinessScheduleAppointmentWithoutAuthInput = {
  BusinessOneId: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  firstName?: InputMaybe<Scalars['String']>;
  floorNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagNumber?: InputMaybe<Scalars['String']>;
  toSee?: InputMaybe<Scalars['String']>;
  vendorItems?: InputMaybe<Array<InputMaybe<VendorItemsInput>>>;
  visitationReason?: InputMaybe<Scalars['String']>;
};

export type BusinessScheduleVisitorInput = {
  address?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  firstName?: InputMaybe<Scalars['String']>;
  floorNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagNumber?: InputMaybe<Scalars['String']>;
  toSee?: InputMaybe<Scalars['String']>;
  vendorItems?: InputMaybe<Array<InputMaybe<VendorItemsInput>>>;
  visitationReason?: InputMaybe<Scalars['String']>;
};

export type BusinessUpdateAdminInput = {
  oneIDorEmail: Scalars['String'];
  role?: InputMaybe<Role>;
};

export type BusinessVerifyOneIdWithTokenInput = {
  checkIn?: InputMaybe<Scalars['DateTime']>;
  token: Scalars['String'];
  visitationReason?: InputMaybe<Scalars['String']>;
  visitorOneID: Scalars['String'];
};

export type BusinessVisitorAttendanceCheckoutInput = {
  checkout?: InputMaybe<Scalars['DateTime']>;
  visitorID: Scalars['ID'];
};

export type BusinessVisitorVerificationWithTokenInput = {
  appointmentID: Scalars['ID'];
  businessID: Scalars['ID'];
  token: Scalars['String'];
  visitorID: Scalars['ID'];
};

export type Bvn = {
  bvn: Scalars['String'];
};

export type Cac = {
  company_name: Scalars['String'];
  rc_number: Scalars['String'];
};

export type CancelTravelInput = {
  _id: Scalars['ID'];
  isCancelled: Scalars['Boolean'];
};

export type ChangeCriminalStatusInput = {
  _id: Scalars['ID'];
  jailTimeComplete: Scalars['Boolean'];
};

export type CreateApiKeyData = {
  client: ApiClient;
  description?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<ConsoleEnv>;
  expires?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  scope: ScopeInput;
};

export type CreateDeveloperBusinessProfileInput = {
  RcNo: Scalars['String'];
  _id: Scalars['ID'];
  about: Scalars['String'];
  businessEmail: Scalars['String'];
  businessLogo?: InputMaybe<Scalars['String']>;
  businessName: Scalars['String'];
  businessWebsite?: InputMaybe<Scalars['String']>;
  oneId: Scalars['String'];
};

export type CustomerAttendanceCheckoutInput = {
  attendanceID: Scalars['ID'];
  businessID: Scalars['ID'];
  customerID: Scalars['ID'];
  customerOneID?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type DeleteEmergencyContact = {
  emergencyContactID: Scalars['ID'];
  profileID: Scalars['ID'];
};

export type DeleteProfile = {
  profileId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DocVerifyWithSelfie = {
  document: Scalars['Upload'];
  documentName: Scalars['String'];
  selfieimage: Scalars['Upload'];
};

export type Document = {
  __typename?: 'document';
  _id?: Maybe<Scalars['ID']>;
  expires?: Maybe<Scalars['DateTime']>;
  issuedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentAnalysisInput = {
  image: Scalars['String'];
  type: DocumentAnalysisTypeEnum;
};

export enum DocumentAnalysisTypeEnum {
  BASE64 = 'base64',
  URL = 'url'
}

export type DocumentInput = {
  document: Scalars['Upload'];
  documentName: Scalars['String'];
  expires?: InputMaybe<Scalars['DateTime']>;
  issuedDate?: InputMaybe<Scalars['DateTime']>;
};

export type EditKeyData = {
  description?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<ConsoleEnv>;
  expires?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<ScopeInput>;
};

export type EmergencyContactInput = {
  address: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  relationship: Scalars['String'];
};

export type GetBusinessCustomerInput = {
  customerID?: InputMaybe<Scalars['ID']>;
  oneID?: InputMaybe<Scalars['String']>;
};

export type GetBusinessVisitorInput = {
  oneID?: InputMaybe<Scalars['String']>;
  visitorID: Scalars['ID'];
};

export type GetCriminalRecordInput = {
  recordId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type GetFilters = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Items = {
  __typename?: 'items';
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  item?: Maybe<Scalars['String']>;
};

export type LoginAuthWithOneIdInput = {
  api_key: Scalars['String'];
  identifier?: InputMaybe<Scalars['String']>;
  mfaLoginCode?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type LoginBusinessInput = {
  businessEmail: Scalars['String'];
  password: Scalars['String'];
};

export enum MeterType {
  POSTPAID = 'postpaid',
  PREPAID = 'prepaid'
}

export type NextOfKinInput = {
  address?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
};

export type Nin = {
  nin: Scalars['String'];
};

export type NotifyMeInput = {
  eventName: Scalars['String'];
  userId: Scalars['ID'];
};

export type Phone = {
  phone: Scalars['String'];
};

export type PrivacyOptionsInput = {
  billing?: InputMaybe<Scalars['Boolean']>;
  education?: InputMaybe<Scalars['Boolean']>;
  emergency?: InputMaybe<Scalars['Boolean']>;
  isBlocked?: InputMaybe<Scalars['Boolean']>;
  kyc_bvn?: InputMaybe<Scalars['Boolean']>;
  kyc_nin?: InputMaybe<Scalars['Boolean']>;
  medical?: InputMaybe<Scalars['Boolean']>;
  nationality?: InputMaybe<Scalars['Boolean']>;
  nextOfKin?: InputMaybe<Scalars['Boolean']>;
  personal?: InputMaybe<Scalars['Boolean']>;
  phoneVerification?: InputMaybe<Scalars['Boolean']>;
  travel?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
  workExperience?: InputMaybe<Scalars['Boolean']>;
};

export type ProfileLookerInputs = {
  lookupOneID: Scalars['String'];
  oneID?: InputMaybe<Scalars['String']>;
  userID?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export enum Provider {
  ABUJA = 'abuja',
  BENIN = 'benin',
  EKO = 'eko',
  ENUGU = 'enugu',
  IBADAN = 'ibadan',
  IKEJA = 'ikeja',
  JOS = 'jos',
  KADUNA = 'kaduna',
  KANO = 'kano',
  PORTHARCOURT = 'portharcourt'
}

export type ResendMfaLoginCodeInput = {
  identifier: Scalars['String'];
  oldMfaLoginCode: Scalars['String'];
};

export enum Role {
  ADMIN = 'admin',
  SUPERADMIN = 'superAdmin',
  USER = 'user'
}

export type SignUpAuthWithOneIdInput = {
  api_key: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  oneId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type SocialMediaInputs = {
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  snapchat?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type StaffCardInput = {
  businessID: Scalars['ID'];
  cardID: Scalars['ID'];
  staffID: Scalars['ID'];
};

export type UpdateAdminProfileImage = {
  adminID: Scalars['ID'];
  logo: Scalars['String'];
};

export type UpdateBusinessLogo = {
  adminID: Scalars['ID'];
  logo: Scalars['String'];
};

export type UpdateBusinessProfileInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateDeveloperBusinessProfileInput = {
  RcNo?: InputMaybe<Scalars['String']>;
  about?: InputMaybe<Scalars['String']>;
  businessEmail?: InputMaybe<Scalars['String']>;
  businessLogo?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  businessWebsite?: InputMaybe<Scalars['String']>;
  oneId?: InputMaybe<Scalars['String']>;
};

export type UpdateEmergencyContact = {
  address?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  recordID: Scalars['ID'];
  relationship?: InputMaybe<Scalars['String']>;
};

export type UpdateTravelRecordInput = {
  _id: Scalars['ID'];
  cause?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  lodgingAddress?: InputMaybe<Scalars['String']>;
  returnDate?: InputMaybe<Scalars['DateTime']>;
  transportMeans?: InputMaybe<Scalars['String']>;
  travelDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateUser = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type VendorItemsInput = {
  count?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  item?: InputMaybe<Scalars['String']>;
};

export type VerifyBusinessRcNumberInput = {
  RcNo: Scalars['String'];
  businessName: Scalars['String'];
  oneId: Scalars['String'];
};

export type VerifyMeter = {
  meterNumber: Scalars['String'];
  provider: Provider;
  type?: InputMaybe<MeterType>;
};
