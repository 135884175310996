import { Box, Divider, Typography } from "@mui/material"
import { Progress, PublicFooter, PublicHeader } from "components"
import { gsap } from "gsap"
import * as React from "react"
import { BoxContainer, CALayoutBox, LogoBox, PaperContainer } from "./styles"
import OneIDLogo from "../../assets/oneid-brand.svg"
import { useNavigate } from "react-router-dom"

interface Props {
  children: React.ReactNode
  primaryHeadline?: string
  secondaryHeadline?: string
  logoHeadline?: string
  loading?: boolean
  logo?: string
  homeUrl?: string
  subText?: string
}

export const CoreAuthLayout: React.FC<Props> = ({
  logo,
  subText,
  loading,
  children,
  homeUrl = "/",
  logoHeadline,
  primaryHeadline,
  secondaryHeadline,
}) => {
  const navigate = useNavigate()
  let boxRef = React.useRef<HTMLDivElement | null>(null)

  const setBoxRef = (el: HTMLDivElement) => {
    if (boxRef.current === null) {
      boxRef.current = el
    }
  }

  React.useEffect(() => {
    localStorage.removeItem("1D_AU")
    localStorage.removeItem("1D_AUR")
  }, [])

  React.useEffect(() => {
    gsap.to(boxRef.current, {
      duration: 1.5,
      ease: "elastic.inOut(2.5, 1)",
      y: 0,
      opacity: 1,
    })
  }, [])

  return (
    <CALayoutBox>
      <PublicHeader />
      <BoxContainer className="box" ref={setBoxRef}>
        <PaperContainer elevation={2} sx={{ borderRadius: "0.85rem" }}>
          {loading && <Progress />}
          {logoHeadline && (
            <div>
              <Box className="logo-wrapper">
                <LogoBox onClick={handleLogoClick} width="25%" height="25%">
                  <img src={logo ?? OneIDLogo} alt="oneid logo" />
                </LogoBox>

                {logoHeadline && (
                  <Typography variant="subtitle2" textAlign="center">
                    {logoHeadline}
                  </Typography>
                )}
              </Box>
              <Divider sx={{ margin: "1rem auto" }} />
            </div>
          )}

          <Box className="header-wrapper">
            {primaryHeadline && (
              <Typography
                variant={
                  primaryHeadline.toLowerCase().includes("create an account")
                    ? "h4"
                    : "h3"
                }
                fontWeight="bold"
                color="primary"
                gutterBottom
              >
                {primaryHeadline}
              </Typography>
            )}
            {subText && <Typography variant="subtitle2">{subText}</Typography>}
          </Box>

          <div className="form-wrapper">
            {secondaryHeadline && (
              <Typography variant="h6" textAlign="center" className="cta-text">
                <small>{secondaryHeadline}</small>
              </Typography>
            )}
            {children}
          </div>
        </PaperContainer>
      </BoxContainer>
      <PublicFooter />
    </CALayoutBox>
  )

  function handleLogoClick() {
    navigate(homeUrl)
  }
}
