import { ApolloProvider } from "@apollo/client"
import { CssBaseline } from "@mui/material"
import { ErrorView, SnackbarContextProvider } from "components"
import { client } from "config"
import { AuthProvider } from "context"
import React from "react"
import { AppTheme } from "./App.theme"
import { AppRoutes } from "./routes"

interface Props {}

interface State {
  error?: Error
}

export default class App extends React.Component<Props, State> {
  public static getDerivedStateFromError(error: Error) {
    return { error }
  }

  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  public render() {
    const { error } = this.state

    if (error) {
      return <ErrorView error={error} />
    }

    return (
      <ApolloProvider client={client}>
        <AuthProvider>
          <AppTheme>
            <SnackbarContextProvider>
              <CssBaseline />

              <AppRoutes />
            </SnackbarContextProvider>
          </AppTheme>
        </AuthProvider>
      </ApolloProvider>
    )
  }
}
