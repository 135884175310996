import { useMediaQuery, useTheme } from "@mui/material"
import { LogoBox } from "components/Layouts/styles"
import { redirect } from "lib"
import * as React from "react"
import OneIDLogo from "../../assets/oneid-brand.svg"
import { PublicHeaderBox } from "./styles"

export const PublicHeader: React.FC = () => {
  const {
    breakpoints: { down },
  } = useTheme()
  const isMobile = useMediaQuery(down("md"))

  return (
    <PublicHeaderBox>
      <div className="info-wrapper">
        <LogoBox
          onClick={() =>
            redirect({
              href: "/",
            })
          }
          width={isMobile ? "20%" : "6%"}
        >
          <img src={OneIDLogo} alt="OneID" />
        </LogoBox>
      </div>
    </PublicHeaderBox>
  )
}
