import { Progress } from "components"
import * as React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

const Login = React.lazy(() => import("../views/login"))
const SignUp = React.lazy(() => import("../views/signup"))
const ForgotPassword = React.lazy(() => import("../views/forgot-password"))
const ResetPassword = React.lazy(() => import("../views/reset-password"))
const AuthWithOneID = React.lazy(
  () => import("../views/AuthWithOneID/AuthWithOneID")
)
const MfaConfirmation = React.lazy(() => import("../views/mfa-confirmation"))
const VerifyEmail = React.lazy(() => import("../views/verify-email"))

export const AppRoutes: React.FC = () => (
  <BrowserRouter>
    <React.Suspense fallback={<Progress />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="auth" element={<AuthWithOneID />} />
        <Route path="mfa-confirmation" element={<MfaConfirmation />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
      </Routes>
    </React.Suspense>
  </BrowserRouter>
)
