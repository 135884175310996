import { Box, Card, Grid, Typography } from "@mui/material"
import { OneIDIcon } from "assets"
import { Progress } from "components/Progress"
import { PublicFooter } from "components/PublicFooter"
import { gsap } from "gsap"
import React from "react"
import { AuthWrap } from "./styles"

interface Props {
  children: React.ReactNode | React.ReactNode[]
  loading?: boolean
  heading?: string
}
export const AuthWithOneIDLayout: React.FC<Props> = ({
  children,
  loading,
  heading,
}) => {
  let boxRef = React.useRef<HTMLDivElement | null>(null)

  const setBoxRef = (el: HTMLDivElement) => {
    if (boxRef.current === null) {
      boxRef.current = el
    }
  }

  React.useEffect(() => {
    gsap.to(boxRef.current, {
      duration: 1.5,
      ease: "elastic.inOut(2.5, 1)",
      y: 0,
      opacity: 1,
    })
  }, [])

  return (
    <AuthWrap justifyContent="center" alignItems="center" container>
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          className="box__gsap"
          ref={setBoxRef}
        >
          <Grid
            className="box__wrap"
            justifyContent="flex-start"
            component={Card}
            elevation={2}
          >
            {loading && <Progress />}
            <Grid className="header">
              <OneIDIcon width="20" height="20" />
              <Typography
                color="text.secondary"
                sx={{ ml: 1 }}
                fontWeight={600}
              >
                {heading ? heading : "Sign in with OneID"}
              </Typography>
            </Grid>

            <Grid className="content">{children}</Grid>
          </Grid>
        </Grid>
      </Box>

      <PublicFooter />
    </AuthWrap>
  )
}
