import { styled } from "@mui/material"

export const PublicHeaderBox = styled("header")(
  ({ theme: { spacing, breakpoints } }) => ({
    width: "100%",
    top: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: spacing(3, 8),

    [breakpoints.down("sm")]: {
      padding: spacing(2),
    },

    "& .info-wrapper": {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
  })
)
