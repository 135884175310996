import { Typography } from "@mui/material"
import { Variant } from "@mui/material/styles/createTypography"
import React from "react"
import { ErrorStack } from "./ErrorStack"

interface Props {
  error: Error
  variant?: Variant
  showStack?: boolean
  className?: string
}

export const ErrorMessage: React.FC<Props> = ({
  error,
  variant,
  showStack = false,
  className,
}) => (
  <React.Fragment>
    <Typography
      color="error"
      component="span"
      variant={variant}
      className={className}
    >
      {error.message}
    </Typography>

    {showStack && error.stack && <ErrorStack stack={error.stack} />}
  </React.Fragment>
)
