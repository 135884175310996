import { AlertColor } from "@mui/material"
import * as React from "react"

export type SnackbarInputType = Error | string

export interface Snackbar {
  open: (input: SnackbarInputType, severity?: AlertColor) => void
  info: (input: SnackbarInputType) => void
  success: (input: SnackbarInputType) => void
  warn: (input: SnackbarInputType) => void
  error: (input: SnackbarInputType) => void
  close: () => void
  isOpen: boolean
  message: string
  severity: AlertColor
}

export const SnackbarContext = React.createContext<Snackbar>({
  isOpen: false,
  message: "",
  severity: "info",

  close() {},
  open() {},
  info() {},
  warn() {},
  success() {},
  error() {},
})
