import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { Config } from "../Config"

const errorLink = onError(({ networkError }) => {
  if (
    networkError &&
    isServerError(networkError) &&
    networkError.statusCode === 401
  ) {
    logout()
  }
})

const httpLink = createHttpLink({ uri: Config.API_BASE_URL })
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("1D_AU")

  return {
    headers: {
      ...headers,
      authorization: token && token,
    },
  }
})
const link = ApolloLink.from([errorLink, httpLink, authLink])

function isServerError(
  error: Error | ServerError | ServerParseError
): error is ServerError | ServerParseError {
  return error.hasOwnProperty("response")
}

function logout() {
  localStorage.setItem("1D_AU", "")
  window.location.hash = "logout"
}

export const client = new ApolloClient({
  // name: "oneid-auth-gateway",
  // version: "1.0",
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
    },
    query: {
      errorPolicy: "all",
      fetchPolicy: "cache-first",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
})
