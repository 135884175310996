import { Box, Divider, Typography } from "@mui/material"
import { Progress, PublicFooter, PublicHeader } from "components"
import { Config } from "config"
import { gsap } from "gsap"
import { redirect } from "lib"
import * as React from "react"
import FrontDeskLogo from "../../assets/FrontDesk_logo.svg"
import { BoxContainer, FDLayoutBox, LogoBox, PaperContainer } from "./styles"

interface Props {
  subText?: string
  loading?: boolean
  logoHeadline?: string
  primaryHeadline?: string
  secondaryHeadline?: string
  children: JSX.Element | JSX.Element[]
}

export const FrontDeskLayout: React.FC<Props> = ({
  subText,
  loading,
  children,
  logoHeadline,
  primaryHeadline,
  secondaryHeadline,
}) => {
  let boxRef = React.useRef<HTMLDivElement | null>(null)

  const goHome = () =>
    redirect({
      href: Config.FRONT_DESK_URL,
    })

  const setBoxRef = (el: HTMLDivElement) => {
    if (boxRef.current === null) {
      boxRef.current = el
    }
  }

  React.useEffect(() => {
    gsap.to(boxRef.current, {
      duration: 1.5,
      ease: "elastic.inOut(2.5, 1)",
      y: 0,
      opacity: 1,
    })
  }, [])

  return (
    <FDLayoutBox>
      <PublicHeader />
      <BoxContainer className="box" ref={setBoxRef}>
        <PaperContainer elevation={4}>
          {loading && <Progress />}

          {logoHeadline && (
            <div>
              <Box className="logo-wrapper">
                <LogoBox onClick={goHome} width="30%">
                  <img src={FrontDeskLogo} alt="oneid logo" />
                </LogoBox>

                {logoHeadline && (
                  <Typography variant="subtitle2" textAlign="center">
                    {logoHeadline}
                  </Typography>
                )}
              </Box>
              <Divider sx={{ margin: "1rem auto" }} />
            </div>
          )}

          <Box className="header-wrapper">
            {primaryHeadline && (
              <Typography
                variant="h3"
                fontWeight="bold"
                color="primary"
                gutterBottom
              >
                {primaryHeadline}
              </Typography>
            )}
            {subText && <Typography variant="subtitle2">{subText}</Typography>}
          </Box>

          <div className="form-wrapper">
            {secondaryHeadline && (
              <Typography variant="h6" textAlign="center" className="cta-text">
                <small>{secondaryHeadline}</small>
              </Typography>
            )}
            {children}
          </div>
        </PaperContainer>
      </BoxContainer>
      <PublicFooter />
    </FDLayoutBox>
  )
}
