import { SVGIconProps } from "./icon-interface"

export const SuccessfulIcon: React.FC<SVGIconProps> = ({
  width = "77",
  height = "81",
  fill = "#0B61DD",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.7894 7.51816C39.8438 6.03115 38.6812 4.77163 37.194 4.82269C30.5058 5.05235 23.9792 7.04345 18.2855 10.6161C11.8294 14.6671 6.74262 20.5643 3.68642 27.541C0.630223 34.5178 -0.25407 42.2515 1.14849 49.7368C2.55104 57.2222 6.17558 64.113 11.551 69.5137C16.9264 74.9144 23.804 78.5751 31.29 80.02C38.7759 81.4649 46.5239 80.6272 53.5269 77.6158C60.5298 74.6043 66.4639 69.5585 70.5577 63.134C74.167 57.4699 76.1987 50.9652 76.469 44.2885C76.5292 42.8001 75.2752 41.6288 73.7863 41.6744C72.2972 41.72 71.1388 42.966 71.061 44.4537C70.7684 50.0481 69.0345 55.4892 66.0066 60.2409C62.494 65.7533 57.4024 70.0828 51.3936 72.6668C45.3848 75.2507 38.7367 75.9694 32.3135 74.7297C25.8903 73.4899 19.9891 70.3489 15.3768 65.7149C10.7645 61.0809 7.65453 55.1683 6.45109 48.7456C5.24764 42.3229 6.0064 35.6872 8.62872 29.7009C11.2511 23.7146 15.6157 18.6546 21.1553 15.1787C25.9323 12.1812 31.3925 10.4813 36.9977 10.2231C38.4839 10.1546 39.735 9.00499 39.7894 7.51816Z"
        fill={fill}
      />
      <path
        d="M35.8591 48.5102L68.43 16.3296C69.5872 15.1863 71.4379 15.152 72.6366 16.2517C73.9254 17.4341 73.9583 19.4558 72.7087 20.6795L35.859 56.7652L19.7975 41.0141C18.7263 39.9637 18.7178 38.2411 19.7786 37.1801C20.803 36.1556 22.4536 36.1229 23.5177 37.1062L35.8591 48.5102Z"
        fill={fill}
      />
    </svg>
  )
}
