import { Grid, Paper, styled, Box } from "@mui/material"
import { blue } from "@mui/material/colors"
import FDBgnd from "../../assets/frontdesk-bg.jpg"

export const CALayoutBox = styled("div")`
  position: relative;
  overflow-y: auto;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .header-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`

export const FDLayoutBox = styled("div")(({ theme }) => ({
  position: "relative",
  overflowY: "auto",
  height: "100vh",
  width: "100vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& .image-wrap": {
    height: "100%",
  },

  "& .side-image": {
    backgroundColor: "blue",
    backgroundImage: `url(${FDBgnd})`,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },

  "& .logo-wrapper": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },

  "& .form-wrap": {
    display: "flex",
    justifyContent: "center",
  },
}))

export const BoxContainer = styled(Box)(({ theme, width }) => ({
  width: `${width ?? "25%"}`,
  transform: "translateY(100px)",
  opacity: 0,
  zIndex: 10,

  [theme.breakpoints.up("lg")]: {
    minWidth: "400px",
    width: "25%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "50%",
  },
  [theme.breakpoints.down("md")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}))

export const PaperContainer = styled(Paper)(
  ({ theme: { spacing, palette, breakpoints } }) => ({
    width: "100%",
    position: "relative",
    background: palette.common.white,
    padding: spacing(4, 8),
    height: "auto",
    margin: "0 auto",

    "& .form-wrapper": {
      marginTop: spacing(3),

      "& a": {
        color: palette.primary.light,
        fontWeight: 600,
        fontSize: "small",
        textDecoration: "none",
      },
    },

    [breakpoints.down("sm")]: {
      padding: spacing(3),
    },
  })
)

export const OverlayBox = styled("div")`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #e5e5e585;
  background-repeat: no-repeat;
  background-size: 100%;
`

export const LogoBox = styled(Box)(({ theme, width, height }) => ({
  cursor: "pointer",
  width: `${width ? width : "20%"}`,
  height: `${height ? height : "20%"}`,
  textAlign: "center",

  "& img": {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}))

export const AuthRedirectBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "1rem",

  "& a": {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

export const AuthWrap = styled(Grid)(
  ({ theme: { spacing, palette, breakpoints } }) => ({
    width: "100vw",
    color: "#5a5757",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    overflow: "auto",

    "& .box__gsap": {
      transform: "translateY(100px)",
      opacity: 0,
    },

    "& .box__wrap": {
      maxWidth: "500px",
      borderRadius: spacing(2),
      minHeight: "400px",
      fontWeight: 500,
      flexDirection: "column",
      position: "relative",

      [breakpoints.down("md")]: {
        maxWidth: "60%",
      },

      [breakpoints.down("sm")]: {
        maxWidth: "90%",
      },

      "& .header": {
        padding: spacing(2), // 0 1em
        borderBottom: `1px solid ${palette.grey[200]}`,
        display: "flex",
        // flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        "& img": {
          width: "20%",
        },
      },

      "& .content": {
        padding: spacing(4, 8, 6),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        [breakpoints.down("sm")]: {
          padding: spacing(6, 2),
        },

        "& .logo": {
          width: "56px",
          height: "56px",
          color: palette.primary.light,
          background: blue[50],
        },

        "& .leadText": {
          fontWeight: 600,
          marginTop: spacing(1),
          padding: 0,
          lineHeight: "20px",

          [breakpoints.down("sm")]: {
            fontSize: "20px",
            lineHeight: "10px",
          },
        },

        "& .continue-to": {
          marginTop: spacing(0.5),
        },

        "& .account_wrap": {
          padding: "0.7em 0",
          borderBottom: `2px solid ${palette.grey[200]}`,
          cursor: "pointer",
          width: "100%",

          "& .icon": {
            borderRadius: "100%",
            backgroundColor: "#436cc4",
            minWidth: "40px",
            maxWidth: "40px",
            minHeight: "40px",
            maxHeight: "40px",
            fontSize: "20px",
            lineHeight: "0",
            fontWeight: "600",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },

          "& svg": {
            fontsize: "35px",
          },

          "& h4": {
            margin: "0",
            padding: "0",
            lineHeight: "25px",
            transition: "all 0.3s ease",
          },
        },

        "& .instruction": {
          marginTop: "30px",
        },

        "& .name": {
          width: "85%",
          maxWidth: "85%",

          "& h4, span": {
            maxWidth: "90%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        },

        // Login with OneID
        "& .authForm": {
          marginTop: spacing(2),

          "& .submit": {
            margin: spacing(2, 0),
          },
        },
      },
    },
  })
)
