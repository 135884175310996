import { AuthContext, AuthUser, SsoOption } from "./AuthContext"
import React from "react"
import { AuthUserResponse, MfaType } from "types"

interface Props {
  children: React.ReactNode
}
export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setAuthUser] = React.useState<AuthUser>({
    identifier: "",
    password: "",
  })
  const [authResponse, setAuthResponse] = React.useState<AuthUserResponse>({
    token: "",
    refreshToken: "",
    mfaEnabled: false,
    mfaType: MfaType.EMAIL_PHONE,
    sentTo: "",
  })
  const [ssoOption, setSsoOption] = React.useState<SsoOption>({
    api_key: "",
    type: "",
    callback: "",
  })

  return (
    <AuthContext.Provider
      value={{
        user,
        ssoOption,
        authResponse,

        setAuthUser,
        setSsoOption,
        setAuthResponse,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
