import { styled } from "@mui/material/styles"

export const ErrorContentBox = styled("div")`
  padding: 30px;
  text-align: center;

  .image {
    height: 60px;
  }
`
