import { useApolloClient } from "@apollo/client"
import * as React from "react"
import { MeDocument, MeQuery } from "./__types/Me.types"

export function useMe() {
  // Initialize Apollo shared client (used throughout application)
  const client = useApolloClient()

  // Put me in state to trigger re-render once it updates
  const [me, setMe] = React.useState(getMe())

  // If me is not present, and an interval wasn't previously set, set an interval to check if `me` loaded every second
  let interval: NodeJS.Timeout | undefined
  if (!me && !interval) {
    interval = setInterval(() => {
      // Check if me is found
      setMe(getMe())
    }, 1000)
    // If found and an interval is set, clear it
  } else if (me && interval) {
    clearInterval(interval)
  }

  // Clear any ongoing intervals if the component is unmounting
  React.useEffect(
    () => () =>
      typeof interval === "undefined" ? undefined : clearInterval(interval),
    [interval]
  )

  return me

  function getMe() {
    return client.readQuery<MeQuery>({
      query: MeDocument,
    })?.getCurrentUser
  }
}
