import { styled } from "@mui/material"

export const PublicFooterBox = styled("footer")(
  ({ theme: { spacing, breakpoints, palette } }) => ({
    width: "100%",
    bottom: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: spacing(0, 20),
    paddingBottom: spacing(1),

    [breakpoints.down("md")]: {
      display: "none",
    },
    "@media screen and (max-height: 820px)": {
      display: "none",
    },

    [breakpoints.down("sm")]: {
      padding: 0,
    },

    "& .divider": {
      borderColor: palette.grey[200],
      [breakpoints.down("sm")]: {
        width: "90%",
      },
    },

    "& .info-wrapper": {
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },

      [breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },

    "& .copyright": {
      marginLeft: spacing(1),
      color: palette.grey[700],

      "& a": {
        fontWeight: "bold",
        paddingLeft: "0.5rem",
        textDecoration: "none",
        color: palette.grey[700],
      },
    },

    "& .list-container": {
      width: "max-content",
      display: "flex",
      justifyContent: "space-around",

      "& .footer-href": {
        color: palette.grey[500],
        textDecoration: "none",

        "&:hover": {
          color: palette.grey[700],
        },
      },

      [breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
)
