import { Box, Typography } from "@mui/material"
import { LeftArrow } from "assets"
import { Link } from "react-router-dom"

interface Props {
  loginURL?: string
  justify?: string
  mt?: number | string
}
export const BackToLogin: React.FC<Props> = ({
  loginURL = "/",
  justify = "left",
  mt = 3,
}) => {
  return (
    <Box
      sx={{
        mt: mt,
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: justify,
      }}
    >
      <Link to={loginURL} style={{ textDecoration: "none" }}>
        <LeftArrow />
        <Typography
          variant="body2"
          component="span"
          sx={{ pl: 1 }}
          color="primary"
        >
          Back to login
        </Typography>
      </Link>
    </Box>
  )
}
