import { AlertColor } from "@mui/material"
import * as React from "react"
import { SharedSnackbar } from "./SharedSnackbar"
import { SnackbarContext, SnackbarInputType } from "./SnackbarContext"

type Props = {
  children: JSX.Element | JSX.Element[]
}

export const SnackbarContextProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [message, setMessage] = React.useState("")
  const [severity, setSeverity] = React.useState<AlertColor>("info")

  return (
    <SnackbarContext.Provider
      value={{
        open: open,
        info: info,
        warn: warn,
        error: error,
        close: close,
        success: success,
        severity: severity,
        isOpen,
        message,
      }}
    >
      <SharedSnackbar />

      {children}
    </SnackbarContext.Provider>
  )

  function open(input: SnackbarInputType, severity?: AlertColor) {
    if (!severity) {
      severity = input instanceof Error ? "error" : "info"
    }

    const message = input instanceof Error ? input.message : input

    setIsOpen(true)
    setMessage(message)
    setSeverity(severity)
  }

  function close() {
    setIsOpen(false)
  }

  function info(input: SnackbarInputType) {
    open(input, "info")
  }

  function success(input: SnackbarInputType) {
    open(input, "success")
  }

  function warn(input: SnackbarInputType) {
    open(input, "warning")
  }

  function error(input: SnackbarInputType) {
    open(input, "error")
  }
}
