import { Typography } from "@mui/material"
import React from "react"

export const ErrorStack: React.FC<{ stack: string }> = ({ stack }) => (
  <React.Fragment>
    <Typography color="inherit" variant="h6">
      Stack
    </Typography>

    <Typography
      color="inherit"
      component="pre"
      style={{ maxHeight: "70%", textAlign: "left", overflow: "auto" }}
    >
      {stack}
    </Typography>
  </React.Fragment>
)
