const env = process.env
const isProd = env.NODE_ENV === "production"

export const Config = {
  PORT: env.PORT ? env.PORT : 5000,
  DEBUG: isProd ? false : true,
  API_BASE_URL: env.REACT_APP_API_BASE_URL || "http://localhost:4000/graphql",
  BASE_URL: env.REACT_APP_BASE_URL || "http://localhost:3000",
  USER_APP_URL: env.REACT_APP_USER_APP_URL || "http://localhost:3010",
  FRONT_DESK_URL: env.REACT_APP_FRONT_DESK_URL || "http://localhost:3001",
  CONSOLE_URL: env.REACT_APP_CONSOLE_URL || "http://localhost:3002",
  COOKIE_DOMAIN: env.REACT_APP_COOKIE_DOMAIN || "localhost",
  COOKIE_EXPIRY: env.REACT_APP_COOKIE_EXPIRY || "15",
}

// REACT_APP_API_BASE_URL=https://stageserver.oneidtech.com/graphql
