import React from "react"
import { AuthUserResponse } from "types"

export type AuthUser = {
  identifier: string
  password: string
}

export type SsoOption = {
  api_key: string
  type: string
  callback: string
}

export interface IAuthContext {
  user: AuthUser
  authResponse: AuthUserResponse
  ssoOption: SsoOption

  setAuthUser(user: AuthUser): void
  setSsoOption(ssoOption: SsoOption): void
  setAuthResponse(response: AuthUserResponse): void
}

export const AuthContext = React.createContext<IAuthContext>({
  user: {
    identifier: "",
    password: "",
  },
  authResponse: {},
  ssoOption: {
    api_key: "",
    type: "",
    callback: "",
  },

  setAuthUser() {},
  setSsoOption() {},
  setAuthResponse() {},
})
