import { urlParamsToObject } from "lib"
import * as React from "react"
import { useLocation } from "react-router-dom"

export function useNativeQueryParams() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function useTransformedQueryParams() {
  const { search } = useLocation()

  return React.useMemo(
    () => urlParamsToObject(new URLSearchParams(search)),
    [search]
  )
}
